.form
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: 3rem
  position: fixed
  top: 0
  left: 0
  bottom: 0
  width: 40%
  @media screen and (max-width: 1024px)
    position: relative
    width: 100%
    padding: 1.5rem
  .form-title
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 2rem
    .logo
      max-height: 96px
      max-width: 50%
    .calculator
      max-height: 64px
      margin-right: 16px
  .form-group
    display: flex
    flex-direction: column
    margin-top: 1.5rem
    font-size: 1.2rem
    @media screen and (max-width: 1024px)
      margin: 2rem 0
    .form-label
      margin-bottom: 1rem
    .form-control
      display: flex
      input
        flex: 1
        background: transparent
        border: none
        font-size: 1.2rem
        min-width: 48px
      button
        transition: background 500ms
        background-color: transparent
        border: 2px solid rgba(#0294ff, 0.75)
        height: 48px
        width: 48px
        min-width: 48px
        border-radius: 100%
        font-size: 2rem
        background-size: 50%
        background-repeat: no-repeat
        background-position: center
        margin-left: 1rem
        outline: none
        cursor: pointer
        &:hover
          background-color: rgba(#0294ff,0.1)
        &.plus
          background-image: url(../images/plus-symbol.svg)
        &.minus
          background-image: url(../images/minus-symbol.svg)