@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,700&display=swap)
@import 'reset.sass'
@import 'form.sass'

*
  font-family: 'Nunito', sans-serif !important
  box-sizing: border-box

body
  background-image: linear-gradient(45deg, #f5f5f5, #fff)
  height: 100vh
  @media screen and (max-width: 1024px)
    height: auto
  &.header-disable
    .form-title, .plan-title
      display: none
    .form .form-group:nth-child(1n)
      margin-top: 1rem
  &.canvas-disable canvas
    display: none

.preload-image
  display: none

.result
  position: absolute
  right: 1.5rem
  bottom: 1.5rem
  text-shadow: 0 0 8px #fff
  border-radius: 8px
  padding: 1.5rem
  padding-bottom: 0
  transition: background-color 500ms
  &:hover
    background: rgba(#fff, 0.75)
  .total
    font-size: 3rem
    text-align: right
  .detail
    text-align: right
    margin-bottom: 0.5rem
    cursor: pointer
    margin-right: -0.25rem
    .item
      position: relative
      padding: 0.25rem 0.5rem
      border-radius: 8px
      &:before
        content: ""
        position: absolute
        right: -8px
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 8px
        width: 8px
        background-color: rgba(#0294ff, 0.75)
        visibility: hidden
        border-radius: 100%
      &.highlight
        &:before
          visibility: visible
  .message
    text-align: right
    margin-top: 0.5rem
    font-size: 1rem
  @media screen and (max-width: 1024px)
    display: flex
    flex-direction: column-reverse
    position: relative
    padding: 1.5rem
    right: auto
    bottom: auto
    .total
      text-align: center
      padding-bottom: 1.5rem
      border-bottom: 1px solid #e5e5e5
    .detail
      text-align: center
      .item
        font-size: 1.5rem
        margin-top: 1.5rem
        margin-bottom: 0
    .message
      margin-top: 0
      font-size: 1.4rem
      text-align: center
      margin-bottom: 1.5rem
      

canvas
  position: fixed
  left: 0
  top: 0
  @media screen and (max-width: 1024px)
    position: relative
    margin-left: 1.5rem

.plan-title
  position: fixed
  top: 3rem
  right: 3rem
  font-size: 2rem
  height: 64px
  display: flex
  align-items: center
  @media screen and (max-width: 1024px)
    position: relative
    top: auto
    right: auto
    justify-content: center