@import "https://fonts.googleapis.com/css?family=Nunito:300,400,700&display=swap";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  outline: none;
}

.form {
  width: 40%;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

@media screen and (width <= 1024px) {
  .form {
    width: 100%;
    padding: 1.5rem;
    position: relative;
  }
}

.form .form-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.form .form-title .logo {
  max-height: 96px;
  max-width: 50%;
}

.form .form-title .calculator {
  max-height: 64px;
  margin-right: 16px;
}

.form .form-group {
  flex-direction: column;
  margin-top: 1.5rem;
  font-size: 1.2rem;
  display: flex;
}

@media screen and (width <= 1024px) {
  .form .form-group {
    margin: 2rem 0;
  }
}

.form .form-group .form-label {
  margin-bottom: 1rem;
}

.form .form-group .form-control {
  display: flex;
}

.form .form-group .form-control input {
  min-width: 48px;
  background: none;
  border: none;
  flex: 1;
  font-size: 1.2rem;
}

.form .form-group .form-control button {
  height: 48px;
  width: 48px;
  min-width: 48px;
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: 2px solid #0294ffbf;
  border-radius: 100%;
  outline: none;
  margin-left: 1rem;
  font-size: 2rem;
  transition: background .5s;
}

.form .form-group .form-control button:hover {
  background-color: #0294ff1a;
}

.form .form-group .form-control button.plus {
  background-image: url("plus-symbol.70e28d04.svg");
}

.form .form-group .form-control button.minus {
  background-image: url("minus-symbol.c90b7468.svg");
}

* {
  box-sizing: border-box;
  font-family: Nunito, sans-serif !important;
}

body {
  height: 100vh;
  background-image: linear-gradient(45deg, #f5f5f5, #fff);
}

@media screen and (width <= 1024px) {
  body {
    height: auto;
  }
}

body.header-disable .form-title, body.header-disable .plan-title {
  display: none;
}

body.header-disable .form .form-group:nth-child(n) {
  margin-top: 1rem;
}

body.canvas-disable canvas, .preload-image {
  display: none;
}

.result {
  text-shadow: 0 0 8px #fff;
  border-radius: 8px;
  padding: 1.5rem 1.5rem 0;
  transition: background-color .5s;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}

.result:hover {
  background: #ffffffbf;
}

.result .total {
  text-align: right;
  font-size: 3rem;
}

.result .detail {
  text-align: right;
  cursor: pointer;
  margin-bottom: .5rem;
  margin-right: -.25rem;
}

.result .detail .item {
  border-radius: 8px;
  padding: .25rem .5rem;
  position: relative;
}

.result .detail .item:before {
  content: "";
  height: 8px;
  width: 8px;
  visibility: hidden;
  background-color: #0294ffbf;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
}

.result .detail .item.highlight:before {
  visibility: visible;
}

.result .message {
  text-align: right;
  margin-top: .5rem;
  font-size: 1rem;
}

@media screen and (width <= 1024px) {
  .result {
    flex-direction: column-reverse;
    padding: 1.5rem;
    display: flex;
    position: relative;
    bottom: auto;
    right: auto;
  }

  .result .total {
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 1.5rem;
  }

  .result .detail {
    text-align: center;
  }

  .result .detail .item {
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  .result .message {
    text-align: center;
    margin-top: 0;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
}

canvas {
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (width <= 1024px) {
  canvas {
    margin-left: 1.5rem;
    position: relative;
  }
}

.plan-title {
  height: 64px;
  align-items: center;
  font-size: 2rem;
  display: flex;
  position: fixed;
  top: 3rem;
  right: 3rem;
}

@media screen and (width <= 1024px) {
  .plan-title {
    justify-content: center;
    position: relative;
    top: auto;
    right: auto;
  }
}

/*# sourceMappingURL=index.59351148.css.map */
